import styled, { css } from "styled-components";
import { colors } from './../../variables';

export const ShowWrapper = styled.div`
  width: 1000px;
  background-color: transparent;
  border-radius: 0px;
  position: relative;
  margin-top: 120px;
  color: #fff;
  z-index: 30;
  left: 50%;
  margin-left: -500px;
  text-align: center;
  @media (min-width: 992px){
    .video-js .vjs-tech{
      
    }
    .myVideo-dimensions.vjs-fluid{
      padding-top: 65%;
    }
    .video-js.vjs-fluid, .video-js.vjs-16-9, .video-js.vjs-4-3{
      
    }
  }

  p{
    color: #fff;
  }
  button{
    justify-content: center;
    display: inline-block;
  }
  @media (max-width: 1600px){
    width: 600px;
    margin-left: -300px;
  }
  @media (max-width: 991px){
    position: relative;
    margin-top: 0px;
    border:0px;
    background-color: transparent;
  }
  @media (max-width: 767px){
    position: relative;
    margin: 0 auto;
    margin-top: 0px;
    width: 95%;
    left: 50%;
    margin-left: -47.5%;
  }
  
`;

export const Sep = styled.div`
  height: 1px;
  width: 100%;
  margin: 25px 0px;
  background-color: #aaa;
  opacity: 0.3;
`;

export const ShowContent = styled.div`
  padding: 0px;
  padding-bottom: 30px;
  color: #fff;
  text-align: center;
  @media (max-width: 767px){
    padding: 30px 20px;
  }
`;

export const ShowTitle = styled.div`
  margin-top: 20px;
  font-size: 22px;
  font-weight: 600;
  color: #fff;
  font-style: normal;
  text-align: center;
  margin-bottom: 20px;
  @media (max-width: 991px){
    color: #fff;
  }
`;

export const ShowMessage = styled.div`

  margin-top: 20px;
  color: #fff;
  text-transform: normal;
  font-size: 16px;
  @media (max-width: 991px){
    color: #fff;
  }
  p{
    color: #fff;
    @media (max-width: 991px){
    color: #fff;
  }
  }
`;

export const Video = styled.div`
  background-color: #000;
  width: 100%;
  border-radius: 0px;
  overflow: hidden;
  position: relative;
  height: auto!important;
  
  margin: 0 auto;
  margin-top: 0px;
  border: 0px solid #aaa;
  @media (max-width: 767px){
    width: 100%;
    height: auto;
  }
`;

export const ShareTitle = styled.div`
  font-size: 12px;
  font-weight: 300;
  color: #000;
  text-transform: uppercase;
  @media (max-width: 991px){
    color: #fff;
  }
`;

export const ShareList = styled.ul`
  margin: 0px;
  padding: 0px;
  margin-top: 15px;
`;

export const ShareListItem = styled.li<{color: string}>`
  list-style: none;
  display: inline-block;
  margin: 5px 5px;
  color: #fff;
  
  a{
    background-color: ${props => props.color};
    color: #fff;
    display: flex;
    font-size: 11px;
    padding: 5px 10px;
    align-items: center;
    cursor: pointer;
    border-radius: 5px;
  }
  img{
    height: 15px;
    width: auto;
    margin-right: 5px;
  }
`;