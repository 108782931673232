import * as React from 'react';
import { MainWrapper, VideoBackground, PictureBackground } from './styled';
import videoMp4 from './images/video.mp4';
import videoWebm from './images/vm-final.webm';
import videoMp4Mobile from './images/mobile.mp4';
import videoWebmMobile from './images/mobile.webm';
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
  } from "react-device-detect";


const Main:React.FunctionComponent = ({children}) => {
    return <MainWrapper>
        <BrowserView>
        <VideoBackground autoPlay muted loop >
            <source src={videoMp4} type="video/mp4" />
            {/*<source src={videoWebm} type="video/webm" />*/}
        </VideoBackground>
        
        </BrowserView>
        {children}
    </MainWrapper>;
}

export default Main;
