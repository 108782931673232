const manifest = {
  VM: true,
  RESSELER: 21,
  CODE_AUTO: true,
  CODE_AUTO_CODE: '4D0D6BNW' //'4DF59911',
};

//const manifest = {
//  VM: false,
//  RESSELER: 88,
//  CODE_AUTO: false,
//  CODE_AUTO_CODE: '4DF62C6J' //'4DF59911',
// };

export default manifest;
