import styled, { css } from "styled-components";
import { colors } from "../../variables";
import icoCheck from './images/ico-check.svg';
import icoArrowDown from './images/ico-down.svg';
import icoArrowDownWhite from './images/ico-down-white.svg';
import icoCross from './images/ico-cross.svg';
import icoInfo from './images/ico-info.svg';
import icoDate from './images/ico-calendar.svg';


export const Catchline = styled.h1`
  position: relative;
  font-size: 19px;
  font-weight: bold;
  color: #3A5179;
  text-transform: uppercase;
  font-style: normal;
  text-align: center;
  @media (max-width: 991px){
    position: relative;
    text-align: center;
    left: inherit;
    font-size: 28px;
    margin-top: 30px;
    position: relative;
  }
  @media (max-width: 767px){
    font-size: 20px;
  }
`;

export const MessageWrapper = styled.div`
  width: 980px;
  position: relative;
  margin: 0 auto;
  min-height: 100vh;
  margin-top: 130px;
  display: flex;
  justify-content: space-around;
  align-items: top;
  border-radius: 20px;
  .only-mobile{
    display: none;
    @media (max-width: 991px){
      display: block;
    }
  }

  .react-datepicker-wrapper{
    display: none;
    @media (min-width: 992px){
      display: block;
    }
  }

  .react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected{
    background-color: #B4985A;
  }
  @media (max-width: 767px){
    .react-datepicker, .react-datepicker__month-container, .react-datepicker-popper{
      width: 100%;
    }
    .react-datepicker__header select{
      width: 100%!important;
      padding-left: inherit!important;
    }
  }
  
  .react-datepicker__header{
    select{
      height: 25px;
    }
  }
  @media (max-width: 991px){
    margin-top: 10px;
    width: 100%;
    display: block;
  }
  
  @media (max-width: 767px){
    width: 98%;
    margin: 0 auto;
    margin-top: 10px;
  }
`;
export const InputDateIcon = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;
  background-image: url(${icoDate});
  background-size: 20px auto;
  background-position: center center;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  opacity: 0.4;
  @media (max-width: 991px){
    background-image: inherit;
  }
  @media (max-width: 767px){
    top: 60px;
  }
`;

export const MessageResumeVideoContainer = styled.div`
  width: 350px;
  text-align: center;
  position: fixed;
  left: 50%;
  top: 150px;
  margin-left: -450px;
  @media (max-width: 991px){
    display: none;
  }
  button{
    margin: 10px 0px;
    display: inline-block;
  }
  h2{
    color: #fff;
    font-size: 18px;
    text-align: left;
    font-weight: 600;
    @media (max-width: 767px){
      font-size: 16px;
    }
  }
`;

export const MessageMobileTitle = styled.div`
  
  @media (min-width: 992px){
    display: none;
  }
  display: block;
  
  h2{
    color: #fff;
    text-transform: normal;
    font-size: 14px;
    font-family: 'Montserrat';
    text-align: center;
    font-weight: 400;
    margin-bottom: 20px;
    margin-top: 20px;
    @media (max-width: 767px){
      font-size: 14px;
    }
  }
`;

export const MessageFormContainer = styled.div`
  width: 500px;
  background-color: #fff;
  border-radius: 20px;
  position: absolute;
  right: 50%;
  margin-right: -450px;
  margin-bottom: 80px;
  z-index: 30;
  @media (max-width: 991px){
    width: 95%;
    margin: 0 auto;
    left: inherit;
    right: inherit;
    margin-right: inherit;
    position: relative;
    background-color: inherit;
  }
  @media (max-width: 767px){
   position: relative;
   left: inherit;
   margin-left: inherit;
   width: 95%;
   margin: 0 auto;
  }
`;

export const MessageResumeVideo = styled.div`
  background-color: #000;
  width: 350px;
  border-radius: 0px;
  overflow: hidden;
  position: relative;
  height: 220px;
  margin: 0 auto;
  margin-top: 10px;
  border: 0px solid #aaa;
  @media (max-width: 991px){
    border: 0px;
  }
  @media (max-width: 767px){
    width: 100%;
    height: 300px;
  }
`;

export const MessageForm = styled.div`
  padding: 20px 40px;
  color: #000;
  @media (max-width: 991px){
    border: 0px;
    button{
      width: 100%;
    }
    @media (max-width: 767px){
      padding: 20px;
    }
  }
  button{
    display: inline-block;
  }
  h3{
    margin-bottom: 20px;
    margin-top: 20px;
    color: #000;
    font-size: 18px;
    font-weight: 600;
    @media (max-width: 991px){
      color: #fff;
    }
    &.h3-add-message{
      margin-top: 0px;
    }
  }
`;

export const ButtonGroup = styled.div`
  display: none;
  @media (max-width: 991px){
    display: flex;
    justify-content: space-between;
    button{
      padding: 15px 20px;
      font-size: 14px;
      color: #000;
      border: 1px solid #000;
      margin-bottom: 0px;
      @media (max-width: 767px){
        font-size: 11px;
      }
    }
  }
`;

export const SubtitleHide = styled.div<{active?: boolean}>`
  display: none;
  align-items: top;
  margin: 0px;
  margin-top: 20px;
  padding: 0px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  overflow: hidden;
  justify-content: space-between;
  border-top: 1px solid #ccc;
  padding-top: 30px;
  ${props => props.active && css`
    display: flex;
  `}
`;

export const InputGroup = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  position: relative;
  &.mobile-one-line{
    @media (max-width: 991px){
      flex-wrap: wrap;
      select, .react-datepicker-wrapper{
        flex: 1 100%;
      }
    }
  }
  select{
    height: 54px;
    margin: 5px;
    margin-top: 0px!important;
    @media (max-width: 991px){
      height: 38px;
      padding: 5px 0px;
      padding-left: 20px;
    }
  }
  .react-datepicker-wrapper{
    width: 100%;
    margin: 5px;
    .react-datepicker__input-container{
      width: 100%;
    }
    input {
      padding: 18px 20px;
      border-radius: 0px;
      color: #000;
      width: 100%;
      display: block;
      position: relative;
      font-size: 14px;
      border: 1px solid #ccc;
    &.error{
      border: 1px solid red;
    }
    span{
      position: absolute;
      right: 5px;
    }
    @media (max-width: 767px){
      padding: 10px 20px;
    }
    &:focus{
      outline: inherit;
    }
  }
    input{
      box-sizing: border-box;
     
    }
  }
  .react-datepicker__tab-loop{
    .react-datepicker-popper{
      z-index: 40;
    }
  }
`;

export const InputGroupArea = styled.div<{active?: boolean}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  display: none;
  position: relative;
  ${props => props.active && css`
    display: flex;
  `}
`;
export const InputInfoIcon = styled.div<{active?: boolean}>`
  position: absolute;
  right: 20px;
  top: 17px;
  background-image: url(${icoInfo});
  background-size: 20px auto;
  background-position: center center;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  opacity: 0.4;
  cursor: pointer;
  @media (max-width: 767px){
    top: 12px;
  }
  ${props => props.active && css`
    opacity: 1;
  `}
`;
export const InputInfo = styled.div<{active?: boolean}>`
  position: absolute;
  right: 0px;
  bottom: -165px;
  background-color: #222;
  width: 300px;
  padding: 20px;
  border-radius: 0px;
  font-size: 11px;
  font-style: italic;
  color: #fff;
  z-index: 9999;
  -webkit-box-shadow: 0px 0px 56px 8px rgba(0,0,0,0.25);
  -moz-box-shadow: 0px 0px 56px 8px rgba(0,0,0,0.25);
  box-shadow: 0px 0px 56px 8px rgba(0,0,0,0.25);
  &:after{
    width: 0;
    height: 0;
    content: '';
    position: absolute;
    top: -10px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 15px solid #222;
    right: 17px;
    margin-left: 15px;
  }
  display: none;
  ${props => props.active && css`
    display: block;
  `}
`;

export const InputField = styled.input`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 13px 20px;
  margin: 5px;
  border-radius: 10px;
  color: #000;
  width: 100%;
  display: block;
  position: relative;
  font-size: 14px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  &.error{
    border: 1px solid red;
  }
  span{
    position: absolute;
    right: 5px;
  }
  @media (max-width: 991px){
    padding: 13px 20px;
    font-size: 11px;
    border: 0px;
  }
  &:focus{
    outline: inherit;
  }
`;
export const ErrorInfo = styled.p`
  color: red;
  font-style: italic;
  font-size: 11px;
  padding: 0px 0px;
  margin: 0px;
`;
export const FieldsRequired = styled.p`
  color: #000;
  font-style: italic;
  font-size: 11px;
  padding: 0px 0px;
  margin: 0px;
  @media (max-width: 991px){
    color: #fff;
  }
`;

export const InputTextarea = styled.textarea`
  padding: 18px 20px;
  margin: 5px;
  border-radius: 10px;
  color: #000;
  width: 100%;
  display: block;
  position: relative;
  height: 100px;
  font-size: 14px;
  font-family: 'Montserrat';
  border: 1px solid #ccc;
  &:focus{
    outline: inherit;
  }
`;

export const CheckboxGroup = styled.div<{active?: boolean}>`
  display: flex;
  margin-bottom: 10px;
  cursor: pointer;
  ${props => props.active && css`
    > div{
      background-color: #00B6B2;
      border: 1px solid #00B6B2;
      background-image: url(${icoCheck});
      background-size: 16px auto;
      background-position: center center;
      background-repeat: no-repeat;
    }
  `}
`;
export const Checkbox = styled.div`
  width: 22px;
  height: 22px;
  border-radius: 0px;
  border: 1px solid #ccc;
  background-color: #fff;
  cursor: pointer;
  display: inline-block;
  flex-shrink: 0;
  @media (max-width: 767px){
    width: 15px;
    height: 15px;
  }
  
`;
export const CheckboxLabel = styled.span`
  font-size: 11px;
  line-height: 16px;
  color: #555;
  margin-left: 15px;
  @media (max-width: 991px){
    font-size: 11px;
    color: #fff;
  }
  a{
    text-decoration: underline;
    color: #3A5179;
    @media (max-width: 991px){
      color: #fff;
    }
    &::hover, &:focus{
      color: #3A5179;
      @media (max-width: 991px){
      color: #fff;
    }
    }
  }
`;


export const InputFieldSpecial = styled.input`
  padding: 16px 20px;
  margin: 0px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  color: #000;
  width: 100%;
  display: block;
  position: relative;
  font-size: 13px;
  border: 1px solid #ccc;
  @media (max-width: 767px){
    padding: 10px 20px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  &:focus{
    outline: inherit;
  }
`;



export const InputFieldLabel = styled.label`
  font-size: 12px;
  font-style: normal;
  color: #000;
`;


export const Sep = styled.div`
  height: 1px;
  width: 100%;
  margin: 15px 0px;
  background-color: #ccc;
`;

export const FormInfo = styled.p`
  font-size: 11px;
  font-style: italic;
  color: #777;
  @media (max-width: 767px){
    font-size: 9px;
  }
`;


export const SendTypeItem = styled.li<{active?: boolean}>`
  display: inline;
  background-color: #ccc;
  width: 100%;
  font-size: 14px;  
  color: #000;
  padding: 10px 0px;
  text-align: center;
  cursor: pointer;
  align-items: center;
  display: flex;
  justify-content: center;
  @media (max-width: 767px){
    font-size: 11px;
  }
  img{
    height: 20px;
    margin-right: 5px;
  }
  ${props => props.active && css`
    background-color: #000;
    color: #fff;
    img{
      filter: invert(1);
    }
  `}
`;
export const SendType = styled.ul`
  position: relative;
  display: flex;
  align-items: center;
  margin: 0px;
  padding: 0px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  overflow: hidden;
  justify-content: space-between;
`;

export const AdditionalMessage = styled.div<{active?: boolean}>`
  position: relative;
  display: flex;
  align-items: top;
  margin: 0px;
  margin-top: 20px;
  padding: 0px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  overflow: hidden;
  justify-content: space-between;
  display: none;
  ${props => props.active && css`
    display: flex;
  `}
`;

export const AdditionalMessageCancel = styled.a`
  text-decoration: underline;
  color: #000;
  font-size: 9px;
  text-transform: uppercase;
  text-align: right;
  cursor: pointer;
`;

export const PlanWrapper = styled.div<{active?: boolean}>`
  display: none;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  ${props => props.active && css`
    display: flex;
    @media (max-width: 767px){
      display: block;
    }
  `}
`;
export const PlanSelectTitle = styled.div`
  color: #000;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: normal;
  margin-bottom: 5px;
  align-items: center;
  display: flex;
  padding-left: 5px;
  @media (max-width: 991px){
    font-size: 9px;
  }
  img{
    height: 20px;
    margin-right: 10px;
    width: auto;
    @media (max-width: 991px){
      height: 15px;
    }
  }
`;
export const PlanSelectElement = styled.div`
  width: 100%;
  margin: 5px;
  margin-top: 10px;
  @media (max-width: 991px){
    input{
      margin: 0px;
    }
  }
  @media (max-width: 767px){
    margin-bottom: 15px;
  }
  &:first-child{
    margin-left: 0px;
  }
  &:last-child{
    margin-right: 0px;
    @media (max-width: 991px){
      width: 100%;
    }
  }
`;
export const PlanSelect = styled.select`
    background-size: 14px auto;
    background-position: 92%;
    background-image: url(${icoArrowDown});
    background-repeat: no-repeat;
    background-color: transparent;
    color: #000;
    border-color: #e3e3e3;
    width: 100%;
    -moz-appearance: none;
    -webkit-appearance: none;
    height: 54px;
    font-size: 14px;
    position: relative;
    padding: 12px 10px 12px 20px;
    
    @media (max-width: 991px){
      font-size: 12px;
    }
    &:focus{
      outline: inherit;
    }
    &:before{
      
    }
    &:after{
      content: '';
      position: absolute;
      width: 20px;
      height: 20px;
      right: 10px;
      top: 10px;
      background-size: 20px auto;
      background-position: center center;
      background-repeat: no-repeat;
      background-image: url(${icoArrowDown});
    }
    
`;
export const PlanOption = styled.option`
  
`;

export const PreviewMobile = styled.div<{active?: boolean}>`
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 80vh;
  z-index: 50;
  background-color: #fff;
  color: #3A5179;
  display: none;
  overflow-y: scroll;
  -webkit-transition: all .3s;
  -moz-transition: all .3s;
  transition: all .3s;
  -webkit-box-shadow: 0px 0px 106px 8px rgba(0,0,0,0.45);
  -moz-box-shadow: 0px 0px 106px 8px rgba(0,0,0,0.45);
  box-shadow: 0px 0px 106px 8px rgba(0,0,0,0.45);
  text-align: center;
  padding: 10px 0px;
  
  button{
    padding: 15px 50px;
    color: #fff;
    @media (max-width: 767px){
      width: 90%;
      padding: 13px 50px;
      display: inline-block;
    }
  }
  @media (max-width: 991px){
    display: block;
    bottom: 0px;
  }
  ${props => props.active && css`
    @media (max-width: 991px){
      display: block;
      bottom: -100%;
    }
  `}
`;

export const PreviewClose = styled.div`
  position: relative;
  width: 30px;
  cursor: pointer;
  height: 30px;
  border-radius: 0px;
  border: 0px solid #000;
  background-image: url(${icoCross});
  background-size: 25px auto;
  background-position: center center;
  background-repeat: no-repeat;
  margin: 0 auto;
  margin-top: 30px;
  
  &:hover{
    background-color: #ccc;
    border:1px solid #ccc;
  }
`;

export const PreviewTitle = styled.div`
  font-size: 30px;
  font-weight: bold;
  font-style: normal;
  text-align: left;
  color: #fff;
  text-transform: uppercase;
  padding: 10px 20px;
  @media (max-width: 767px){
    font-size: 18px;
  }
`;

export const PreviewMessage = styled.div`
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  font-weight: normal;
  padding: 10px 20px;
  text-transform: uppercase;
  @media (max-width: 767px){
    font-size: 12px;
    line-height: 18px;
  }
`;

export const PreviewVideo = styled.div`
  background-color: #000;
  width: 350px;
  border-radius: 0px;
  overflow: hidden;
  position: relative;
  height: 220px;
  text-align: center;
  margin: 0 auto;
  margin-top: 10px;
  border: 1px solid #aaa;
  
  @media (max-width: 767px){
    width: 90%;
    height: 200px;
  }
`;
export const StepNumber = styled.div`
  font-size: 14px;
  text-align: right;
  color: #000;
  text-transform: uppercase;
  z-index: 40;
  margin-bottom: 10px;
  font-weight: bold;
  position: relative;
  @media (max-width: 991px){
    display: block;
    text-align: center;
    color: #fff;
  }
`;
export const SendSelectElement = styled.div`
  width: 100%;
  margin: 0px;
  display: flex;
  margin-bottom: 10px;
  @media (max-width: 767px){
    margin-bottom: 15px;
    display: block;
    input{
      box-sizing: border-box;
    }
  }
  &:first-child{
    margin-left: 0px;
  }
  .react-tel-input input {
    height: 50px !important;
  }
  &:last-child{
    margin-right: 0px;
    width: 150px;
    @media (max-width: 767px){
      width: 100%;
      
    }
  }
`;
export const SendSelect = styled.select`
    background-size: 14px auto;
    background-position: 92%;
    background-image: url(${icoArrowDownWhite});
    background-repeat: no-repeat;
    background-color: transparent;
    color: #fff;
    font-weight: 600;
    background-color: #FBB05A;
    border-color: transparent;
    width: 70%;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    -moz-appearance: none;
    -webkit-appearance: none;
    height: 50px;
    font-size: 14px;
    position: relative;
    padding: 12px 10px 12px 20px;
    
    @media (max-width: 991px){
      font-size: 12px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      border-bottom-left-radius: 0px;  
    }
    @media (max-width: 767px){
      width: 100%;
    }
    &:focus{
      outline: inherit;
    }
    &:before{
      
    }
    &:after{
      content: '';
      position: absolute;
      width: 20px;
      height: 20px;
      right: 10px;
      top: 10px;
      background-size: 20px auto;
      background-position: center center;
      background-repeat: no-repeat;
      background-image: url(${icoArrowDownWhite});
    }
    
`;
export const SendOption = styled.option`
  
`;

export const SendReinsurance = styled.p`
  font-size: 13px;
`;


export const Terms = styled.p`
  font-size: 11px;
  color: #3A5179;
  line-height: 16px;
  padding-left: 40px;
  a{
    text-decoration: underline;
    color: #3A5179;
  }
`;