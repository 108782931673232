import styled from "styled-components";
import backgroundMobile from './images/background_mobile.jpg';
import backgroundDesktop from './images/video.jpg';

export const MainWrapper = styled.div`
  background-color: #000;
  position: relative;
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-image: url(${backgroundDesktop});
  background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
`;

export const VideoBackground = styled.video`
    position: fixed;
    right: 0;
    top: 0;
    min-width: 100%;
    min-height: 100%;
    width: 100%;
    height: auto;
    background-size: cover;
    object-fit: cover;
`;

export const PictureBackground = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    min-width: 100%;
    width: 100%;
    height: 100%;
    background-size: cover;
    object-fit: cover;
    background-color: #fff;
    background-size: cover;
    background-position: center bottom;
    background-image: url(${backgroundDesktop});
    background-repeat: no-repeat;
    @media (max-width: 991px){
      background-image: none;
      background-position: bottom center;
      background-image: url(${backgroundMobile});
    }
`;
